/* You can add global styles to this file, and also import other style files */

body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin-bottom: 50px;
  padding-bottom: 25px;
}

footer {
  position: fixed;
  bottom: 0;
  border-top: 1px solid darkgrey;
  width: 100%;
  max-height: 120px;
  background-color: white;
  text-align: center;
  padding-top: 15px;
}

.pricing-header {
  background-color: #f8f9fa;
  padding: 3rem 1rem;
  text-align: center;
}

.feature {
  margin: 2rem 0;
}

.feature-icon {
  font-size: 2rem;
  color: #007bff;
}

.feature h4 {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.pricing-plan {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 2rem;
  margin: 1rem;
  text-align: center;
}

.pricing-plan h2 {
  font-size: 1.5rem;
}

.pricing-plan .price {
  font-size: 2rem;
  color: #333;
  margin: 1rem 0;
}

.cta-button {
  margin-top: 1.5rem;
}
